<template>
    <article class="section">
        <div class="container">
            <header class="block">
                <div class="level">
                    <div class="level-left">
                        <h1 class="title">Log View</h1>
                    </div>
                </div>
            </header>
            <section class="block">
                <pre>{{ loglines }}</pre>
            </section>
        </div>
    </article>
</template>

<script>
export default {
    data() {
        var today = new Date()
        var isotoday = today.toISOString().slice(0, 10)
        var url = `https://api.innosend.eu/fetchlog?apikey=blauwvakantievierkantgieterbewolktlang&fromdate=${isotoday}&todate=${isotoday}&fmt=txt`

        fetch(url)
            .then((response) => response.text())
            .then((body) => {
                this.loglines = body
            });

        return {
            loglines: "Momentje, ophalen log..."
        }
    }
}
</script>
